import styled from 'styled-components';
import {  space, layout, typography, border, color, flexbox, position, ColorProps,
          BorderProps,TypographyProps,SpaceProps, LayoutProps, FlexboxProps, PositionProps
        } from 'styled-system'

interface Props extends SpaceProps, LayoutProps, TypographyProps,BorderProps,ColorProps, FlexboxProps, PositionProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #F5F5F5;
`;

const Flex = styled.div<Props>`
  display: flex;
  align-items: center;
  ${flexbox}
`
const Row = styled.div<Props>`
  padding: .5rem .625rem .5rem 1rem;
  background: #FFF4EB;
  border-bottom: 1px solid #FFDDBF;
  ${space}
`
const Space = styled.div<Props>`
  ${space}
  ${layout}
  ${typography}
  ${border}
  ${color}
  ${flexbox}
  ${position}
`;

const SpaceShadow = styled(Space)<Props>`
  box-shadow: 0px 2px 2px rgba(245,118,0,0.35);
  z-index: 999;
`;

const Number = styled.div<Props>`
  font-family: 'Oxygen', sans-serif;
  line-height: 1;
  ${typography}
  ${space}
`;

Space.defaultProps = {
  theme: {
    space: [0]
  }
}


export default { Container, Flex, Row, Space, SpaceShadow, Number};